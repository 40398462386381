<template>
  <div>
    <div class="title">
      <h1>Estadísticas</h1>
      <div class="toolbar">
        <button @click="exportToExcel" type="button" class="btn">Exportar</button>
      </div>
    </div>
    <!--Stats-->
    <div class="stats mb-32">
      <!--Sales-->
      <div class="stats__item stats__item--success">
        <span class="stats__title">Ventas totales</span>
        <span class="stats__highlight">{{
            Intl.NumberFormat('en-PE', {
              style: 'currency',
              currency: 'PEN'
            }).format(this.total)
          }}</span>
      </div>
      <!--Invoices-->
      <div class="stats__item stats__item--warning">
        <span class="stats__title">Comprobantes</span>
        <span class="stats__highlight">{{
            sales.length
          }}</span>
      </div>
      <!--Products-->
      <div class="stats__item">
        <span class="stats__title">Productos vendidos</span>
        <span class="stats__highlight">{{ sales.reduce((a, c) => {return a + c.products.length}, 0) }}</span>
      </div>
    </div>
    <!--End-->
    <!---Clients-->
    <div class="bills">
      <div class="table">
        <div class="table__header table__header--two">
          <div><span>EXPOSITOR</span></div>
          <div><span>TOTAL</span></div>
        </div>
        <div class="table__content">
          <div v-for="(o, index) in clients" :key="index" class="row row--two">
            <div class="row__item">
              <span class="name">EXPOSITOR</span>
              <span>{{ o.alias }}</span>
            </div>
            <div class="row__item">
              <span class="name">TOTAL</span>
              <span>{{
                  Intl.NumberFormat('en-PE', {
                    style: 'currency',
                    currency: 'PEN'
                  }).format(o.total)
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---End-->
    <!--Filter-->
    <div class="filter">
      <div class="filter__trigger" @click="showFilter= !showFilter">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Filtrar</title>
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                d="M32 144h448M112 256h288M208 368h96"/>
        </svg>
      </div>
      <div id="filterContent" class="filter__content" :class="{'show': showFilter}">
        <!--Deadline-->
        <div class="form-group">
          <label for="deadline">Filtrar por fecha</label>
          <v-date-picker
            id="deadline"
            name="deadline"
            v-model="filter.dateOfIssue"
            :dot="true"
            color="red"
            is-expanded
            is-range
            :timezone="`America/Lima`"
          />
        </div>
        <button @click="goFilter" class="btn">Filtrar</button>
      </div>
    </div>
    <!--End Filter-->
  </div>
</template>

<script>
import { Timestamp, db } from '@/firebase/firebaseConfig'

const moment = require('moment')

export default {
  name: 'Stats',
  data () {
    return {
      filter: {},
      showFilter: true,
      exchangeRate: 0,
      sales: [],
      total: 0,
      clients: []
    }
  },
  watch: {
    /**
     * Show filter
     * */
    showFilter (value) {
      if (value) {
        setTimeout(() => {
          document.getElementById('filterContent').classList.add('unhide')
        }, 20)
      } else {
        setTimeout(() => {
          document.getElementById('filterContent').classList.remove('unhide')
        }, 20)
      }
    }
  },
  async created () {
    try {
      this.$store.dispatch('showProgress')
      await this.getSales('')
    } catch (e) {
      console.log(e)
    } finally {
      this.$store.dispatch('hideProgress')
    }
  },
  methods: {
    /**
     * Filter
     * @returns {Promise<void>}
     */
    async goFilter () {
      try {
        if (this.filter.dateOfIssue.start && this.filter.dateOfIssue.end) {
          this.showFilter = false
          await this.getSales('filter')
          this.calculateTotals()
        }
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al filtrar')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    },
    /**
     * Exports to excel
     */
    async exportToExcel () {
      try {
        import('@/vendor/Export2Excel').then(excel => {
          const list = []
          // Add expenses
          this.expensesList.forEach((e) => {
          })
          // Add incomes
          this.incomesList.forEach((e) => {
          })
          const headerVal = ['type', 'local', 'total', 'currency']
          const headerTitle = ['Tipo', 'local', 'Monto', 'Moneda']
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: 'Resumen ingresos y egresos',
            autoWidth: true,
            bookType: 'xlsx'
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    /**
     * Get incomes
     * @returns {Promise<void>}
     */
    async getSales (type) {
      this.total = 0
      this.sales = []
      this.clients = []
      let start = null
      let end = null

      if (type === 'filter') {
        start = this.filter.dateOfIssue.start
        end = this.filter.dateOfIssue.end
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(0)
      } else {
        start = new Date()
        end = new Date()
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setDate(moment().daysInMonth())
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(0)
      }
      const startTime = Timestamp.fromDate(new Date(start))
      const endTime = Timestamp.fromDate(new Date(end))
      console.log(startTime)
      console.log(endTime)
      const querySnap = await db.collection('sales')
        .where('dateOfIssue', '>=', startTime)
        .where('dateOfIssue', '<=', endTime)
        .orderBy('dateOfIssue', 'asc').get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        this.sales.push(obj)
        this.total += obj.total
        console.log(obj.total)
      })
    },
    /**
     * Calculate totals
     */
    calculateTotals () {
      // Get sales
      this.sales.forEach((s) => {
        let findClient = this.clients.find((c) => c.id === s.client.id)
        if (!findClient) {
          this.clients.push({
            ...s.client,
            total: s.total
          })
        } else {
          findClient += s.total
        }
      })
      this.clients.sort((a, b) => b.total - a.total)
    }
  }
}
</script>
